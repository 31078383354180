var resizeTimer; 
var mobile_trigger_width    = 768; 

$(function () {
    initHeaderSearch();
    innitNav();  
    initBackToTop();
    initContact();
    initSlick();
    initMagnific();
    initHomepage();
    initFooter();

    initUpdates();
});

// Wel niet tonen van back to top
$(window).scroll(function() {
    if ($(this).scrollTop()) {
        $('#toTop').fadeIn();
    } else {
        $('#toTop').fadeOut();
    }
});

function initHeaderSearch() {
    if ($('#header .search form').length) {
        if ($('#header .search form input').val() !== '') {
            $('#header .search form input').addClass('active');
        }

        $('#header .search form').mouseover(function() {
            $(this).find('input').addClass('active');
        })
        .mouseleave(function () {
            if ($(this).find('input').is(':focus')) {
                // do nothing
            } else {
                if ($(this).find('input').val() == '') {
                    $(this).find('input').removeClass('active');
                }
            }
        });

        $('#header .search form input').blur(function () {
            if ($(this).val() == '') {
                $(this).removeClass('active');
            }
        });
    }
}

$(window).resize(function () {
    clearTimeout($.data(this, 'resizeTimer'));
    $.data(this, 'resizeTimer', setTimeout(function () {
        innitNav();   
        initHomepage();
         if ($('.carousel').length > 0) $('.carousel').slick('resize'); 
    }, 100));
});

$(window).on('orientationchange', function() {
  if ($('.carousel').length > 0) $('.carousel').slick('resize'); 
});


var innitNav = function(){
    if($( window ).width() < mobile_trigger_width){
        $('#menu').slicknav({
            label: '',
            appendTo: '#mobilenav',
            easingOpen: "swing",
            easingClose: "swing",
            closedSymbol: '<i class="fa fa-chevron-right" aria-hidden="true"></i>',
            openedSymbol:'<i class="fa fa-chevron-down" aria-hidden="true"></i>'
        });
        $('nav').hide();
        $('.slicknav_menu').show();

        $(".slicknav_nav").prepend($("#header .search"));

    }else{
        $('nav').show();
        $('.slicknav_menu').hide();
        $("#header-search").prepend($("#header .search"));
    }
}  

var initMagnific = function(){
    $('.image-popup').magnificPopup({
        type: 'image',
        closeOnContentClick: true,
        mainClass: 'mfp-img-mobile',
        image: {
            verticalFit: true
        }
    });
}

var initSlick = function() {
    // Default page
    if ($('.carousel').length > 0) {
        $('.carousel').slick({
            dots: true,
            arrows: false,
            autoplay: true
        });
        $('.carousel button.slick-prev').html('<i class="fa fa-angle-left" aria-hidden="true"></i>');
        $('.carousel button.slick-next').html('<i class="fa fa-angle-right" aria-hidden="true"></i>');
    }
} 


var initHomepage = function(){
    if($( window ).width() > mobile_trigger_width){
        if( $('#content-blocks').length ){
            $('#content-blocks .matchHeight').matchHeight({
                byRow: true,
                property: 'height',
                target: null,
                remove: false
            }); 
        }
    }
}

 
var initContact = function() { 
    if( $('#gmap_wrapper') .length > 0 ){
        var latlng = new google.maps.LatLng($('#gmap_locatie').data('lat'), $('#gmap_locatie').data('lng'));
        map = new google.maps.Map(document.getElementById("gmap_wrapper"), {
            zoom: 15,
            mapTypeId: google.maps.MapTypeId.ROADMAP,
            center: latlng,
            scrollwheel: false
        });
        var marker = new google.maps.Marker({
            position: latlng,
            map: map , 
            icon: '/img/marker.png'
        });
    }
}
 


var initBackToTop = function(){ 
    $("#toTop").click(function (event) {
        event.preventDefault();
        $("html, body").animate({scrollTop: 0}, 1000);
    });


    if ($('.BackToTop').length > 0) {
      $('.BackToTop p').click(function(){
          $('html,body').animate({ scrollTop:0 }, 'slow');
      });
    }

}

 var initFooter = function(){
    showBubble();
    var f = setInterval(showBubble, 10000);
 }

function hideBubble() {
    $('.bubble').each(function(index, element) {
        var sleepTime = Math.floor(Math.random() * 2000);
        var t = setTimeout(function() {
            var d = Math.floor(Math.random() * 2000);
            $(element).fadeTo(d, 0);
        }, sleepTime);
    }); 
}

function showBubble(){
    $('.bubble').each(function(index, element) {
        var sleepTime = Math.floor(Math.random() * 2000);
        var t = setTimeout(function() {
            var d = Math.floor(Math.random() * 1000);
            $(element).fadeTo(d, 0.99);
        }, sleepTime);
    });

    var h = setTimeout(hideBubble, 5000);
}

function initUpdates() {
    if ($('#content.updates').length) {
        $('#content.updates table tr').each(function() {
            if ($(this).hasClass('clickable')) {
                $(this).click(function() {
                    window.location = $(this).data('href');
                });
            }
        });
    }
}