$(function () {
    initDiensten();
    initFindLocation();
});

function initFindLocation() {
    if ($('#my-location').length) {
        $('#my-location').click(function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showLocation);
            }
        });
    } else if ($('.location-page').length) {
        $('.location-page').click(function () {
            if (navigator.geolocation) {
                navigator.geolocation.getCurrentPosition(showLocationPage);
            }
        });
    }
}

function showLocation(position) {
    var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    geocoder = new google.maps.Geocoder();

    geocoder.geocode({'latLng': latlng}, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
                for (j = 0; j < results[0].address_components.length; j++) {
                    if (results[0].address_components[j].types[0] == 'postal_code') {
                        $('#searchaddress').val(results[0].address_components[j].short_name);

                        $('#frmDiensten').submit();
                    }
                }
            }
        } else {
            console.log("Geocoder failed due to: " + status);
        }
    });
}

function showLocationPage(position) {
    var latlng = new google.maps.LatLng(position.coords.latitude, position.coords.longitude);
    geocoder = new google.maps.Geocoder();

    geocoder.geocode({'latLng': latlng}, function (results, status) {
        if (status == google.maps.GeocoderStatus.OK) {
            if (results[0]) {
                for (j = 0; j < results[0].address_components.length; j++) {
                    if (results[0].address_components[j].types[0] == 'postal_code') {
                        $('#searchaddress').val(results[0].address_components[j].short_name);

                        $('#frmDiensten').submit();
                    }
                }
            }
        } else {
            console.log("Geocoder failed due to: " + status);
        }
    });
}

function initDiensten() {
    const isIE = /*@cc_on!@*/false || !!document.documentMode;

    $.ajax({
        method: 'POST',
        headers: {
            'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
        },
        beforeSend: function () {
            $('#btn-search #loader').fadeIn('fast');
            $('#map-holder .overlay').fadeIn('fast');

            if (isIE) {
                $('#map-holder .overlay .loading').fadeOut('fast');
                $('#map-holder .overlay .internetexplorer').fadeIn('fast');
                throw new Error("Stop script");
            } else {
                $('#map-holder .overlay .loading').fadeIn('fast');
            }
        },
        url: '/request/locations',
        data: {
            query: $('#searchaddress').val()
        }
    }).done(function (response) {
        if (JSON.parse(response).error === true) {
            $('#map-holder .overlay .loading').fadeOut('fast');
            $('#map-holder .overlay').fadeIn('fast');
            $('#map-holder .overlay .error').fadeIn('fast');
        }
        if ($('#searchaddress').val() !== '') {
            initDienstenLijst(response);
            initMaps(response, true);
        } else {
            initMaps(response);
        }

        setTimeout(function () {
            $('#btn-search #loader').fadeOut('fast');
            $('#map-holder .overlay .error').fadeOut('fast');
            $('#map-holder .overlay .loading').fadeOut('fast');

            if (JSON.parse(response).length === 0) {
                $('#map-holder .overlay').fadeIn('fast');

                setTimeout(function () {
                    $('#map-holder .overlay .no-results').fadeIn('fast');
                }, 300);
            } else {
                $('#map-holder .overlay').fadeOut('fast');
            }
        }, 500);
    });

    initDienstenSearch();
    initDienstenSearchAgain();
    initDienstenSearchError();
}

function initDienstenLijst(response) {
    if ($('#diensten-lijst').length > 0) {

        if (response.length === 0) {
            response = false;
        }

        var dienst = '',
            json = '';

        locations = JSON.parse(response);

        var amount = 0;
        locations.forEach(function (e) {
            amount++;
        });

        $('#diensten-lijst').html('');

        for (var i = 0; i < amount; i++) {
            dienst = '<div class="dienst" data-item="' + i + '">';

            if (locations[i].mainoffice != null) {
                console.log(locations[i]);
                if (locations[i].distance || locations[i].distance === 0) {
                    dienst += '<h3 class="title">' + locations[i].mainoffice.name + ' <small class="pull-right">' + Math.ceil(locations[i].distance) + ' km</small></h3>';
                } else {
                    dienst += '<h3 class="title">' + locations[i].mainoffice.name + '</h3>';
                }

                var short_description = '';
                if (locations[i].short_description != null && locations[i].short_description != "") short_description = '<p style="margin: 5px 0; color: #e00916;"><strong>Let op: </strong>' + locations[i].short_description + '</p>';

                var telephone = '';
                var fax = '';
                var emailaddress = '';
                var website = '';

                if (locations[i].mainoffice.phone != null && locations[i].mainoffice.phone != "") telephone = '<p class="tel">T: <a href="tel:' + locations[i].mainoffice.phone + '" target="_blank" title="Neem direct contact op">' + locations[i].mainoffice.phone + '</a></p>';
                if (locations[i].mainoffice.fax != null && locations[i].mainoffice.fax != "") fax = '<p class="fax">F: ' + locations[i].mainoffice.fax + '</p>';
                if (locations[i].mainoffice.email_on_website != null && locations[i].mainoffice.email_on_website != "" && locations[i].mainoffice.id != '45' && locations[i].mainoffice.id != '99') emailaddress = '<p class="email">E: <a href="mailto:' + locations[i].mainoffice.email_on_website + '" target="_blank" title="Neem contact op">' + locations[i].mainoffice.email_on_website + '</a></p>';
                if (locations[i].mainoffice.website != null && locations[i].mainoffice.website != "") website = '<p class="website">W: <a href="http://' + locations[i].mainoffice.website + '" target="_blank" title="Bezoek de website">' + locations[i].mainoffice.website + '</a></p>';

                dienst += '<div class="inner">' + short_description +
                    '<h3><span>Bezoekadres:</span><br>' + locations[i].street + ' ' + locations[i].housenumber + locations[i].housenumber_additions + '</h3>' +
                    '<p>' + locations[i].zipcode + ' ' + locations[i].city + '</p>';

                if (locations[i].mainoffice.name.length > 0) {
                    dienst += '<h3 style="padding-top: 10px"><span>Postadres:</span><br />' + locations[i].mainoffice.street + ' ' + locations[i].mainoffice.housenumber + locations[i].mainoffice.housenumber_additions + '</h3>' +
                        '<p>' + locations[i].mainoffice.zipcode + ' ' + locations[i].mainoffice.city + '</p>';
                }
            } else {
                if (locations[i].distance || locations[i].distance === 0) {
                    dienst += '<h3 class="title">' + locations[i].name + ' <small class="pull-right">' + Math.ceil(locations[i].distance) + ' km</small></h3>';
                } else {
                    dienst += '<h3 class="title">' + locations[i].name + '</h3>';
                }

                var short_description = '';
                if (locations[i].short_description != null && locations[i].short_description != "") short_description = '<p style="margin: 5px 0; color: #e00916;"><strong>Let op: </strong>' + locations[i].short_description + '</p>';

                var telephone = '';
                var fax = '';
                var emailaddress = '';
                var website = '';

                if (locations[i].phone != null && locations[i].phone != "") telephone = '<p class="tel">T: <a href="tel:' + locations[i].phone + '" target="_blank" title="Neem direct contact op">' + locations[i].phone + '</a></p>';
                if (locations[i].fax != null && locations[i].fax != "") fax = '<p class="fax">F: ' + locations[i].fax + '</p>';
                if (locations[i].email_on_website != null && locations[i].email_on_website != "" && locations[i].id != '45' && locations[i].id != '99') emailaddress = '<p class="email">E: <a href="mailto:' + locations[i].email_on_website + '" target="_blank" title="Neem contact op">' + locations[i].email_on_website + '</a></p>';
                if (locations[i].website != null && locations[i].website != "") website = '<p class="website">W: <a href="http://' + locations[i].website + '" target="_blank" title="Bezoek de website">' + locations[i].website + '</a></p>';

                dienst += '<div class="inner">' + short_description +
                    '<h3><span>Bezoekadres:</span><br>' + locations[i].street + ' ' + locations[i].housenumber + locations[i].housenumber_additions + '</h3>' +
                    '<p>' + locations[i].zipcode + ' ' + locations[i].city + '</p>';

                if (locations[i].name.length > 0) {
                    dienst += '<h3 style="padding-top: 10px"><span>Postadres:</span><br />' + locations[i].street + ' ' + locations[i].housenumber + locations[i].housenumber_additions + '</h3>' +
                        '<p>' + locations[i].zipcode + ' ' + locations[i].city + '</p>';
                }
            }

            dienst +=
                telephone +
                fax +
                emailaddress +
                website +
                '</div>',
                '</div>';

            if (locations[i].distance !== null) {
                $('#diensten-lijst').append(dienst);
            }
        }
    }
}

function initMaps(json, fitBounds) {
    if (typeof google !== 'object' || !$('#map').length) {
        return;
    }

    var amount = 0;

    if (!fitBounds) {
        fitBounds = false;
    }

    json = JSON.parse(json);

    json.forEach(function (e) {
        amount++;
    });

    var locations = [],
        markers = [];

    for (var i = 0; i < amount; i++) {
        locations.push(
            {
                id: json[i].id,
                lat: parseFloat(json[i].latitude),
                lng: parseFloat(json[i].longitude),
                name: json[i].name,
                address: json[i].street + ' ' + json[i].housenumber + json[i].housenumber_additions,
                zipcode: json[i].zipcode,
                city: json[i].city,
                phone: json[i].phone,
                fax: json[i].fax,
                email_on_website: json[i].email_on_website,
                website: json[i].website
            }
        );
    }

    var bounds = new google.maps.LatLngBounds();

    var map = new google.maps.Map(document.getElementById('map'), {
        center: {lat: 52.2076825, lng: 4.15851}
    });

    for (i = 0; i < locations.length; i++) {
        var icon = new google.maps.MarkerImage(
            '/img/map/my-marker.png',
            null, /* size is determined at runtime */
            null, /* origin is 0,0 */
            null, /* anchor is bottom center of the scaled image */
            new google.maps.Size(25, 36)
        );

        var marker = new google.maps.Marker({
            position: new google.maps.LatLng(locations[i].lat, locations[i].lng),
            map: map,
            icon: icon
        });

        bounds.extend(marker.position);

        google.maps.event.addListener(marker, 'click', (function (marker, i) {
            return function () {
                var telephone = '';
                var fax = '';
                var emailaddress = '';
                var website = '';
                if (locations[i].phone != null && locations[i].phone != "") telephone = '<p class="tel">T: <a href="tel:' + locations[i].phone + '" target="_blank" title="Neem direct contact op">' + locations[i].phone + '</a></p>';
                if (locations[i].fax != null && locations[i].fax != "") fax = '<p class="fax">F: ' + locations[i].fax + '</p>';
                if (locations[i].email_on_website != null && locations[i].email_on_website != "" && locations[i].id != '45' && locations[i].id != '99') emailaddress = '<p class="email">E: <a href="mailto:' + locations[i].email_on_website + '" target="_blank" title="Neem contact op">' + locations[i].email_on_website + '</a></p>';
                if (locations[i].website != null && locations[i].website != "") website = '<p class="website">W: <a href="http://' + locations[i].website + '" target="_blank" title="Bezoek de website">' + locations[i].website + '</a></p>';

                infoBubble = new InfoBubble({
                    map: map,
                    content:
                        '<div class="inner">' +
                        '<h2>' + locations[i].name + '</h2>' +
                        '<h3><span>Bezoekadres:</span><br>' + locations[i].address + '</h3>' +
                        '<p>' + locations[i].zipcode + ' ' + locations[i].city + '</p>' +
                        website +
                        '</div>',
                    shadowStyle: 0,
                    padding: 0,
                    borderRadius: 0,
                    arrowSize: 10,
                    borderWidth: 0,
                    minWidth: 320,
                    maxWidth: 320,
                    closeSrc: '../img/map/close.png'
                });

                infoBubble.open(map, marker);
            }
        })(marker, i));

        markers.push(marker);
    }

    $('#diensten-lijst .dienst').each(function () {
        $(this).click(function () {
            if ($(window).width() > 767) {
                var telephone = '';
                var fax = '';
                var emailaddress = '';
                var website = '';
                if (locations[$(this).attr('data-item')].phone != null && locations[$(this).attr('data-item')].phone != "") telephone = '<p class="tel">T: <a href="tel:' + locations[$(this).attr('data-item')].phone + '" target="_blank" title="Neem direct contact op">' + locations[$(this).attr('data-item')].phone + '</a></p>';
                if (locations[$(this).attr('data-item')].fax != null && locations[$(this).attr('data-item')].fax != "") fax = '<p class="fax">F: ' + locations[$(this).attr('data-item')].fax + '</p>';
                if (locations[$(this).attr('data-item')].email_on_website != null && locations[$(this).attr('data-item')].email_on_website != "" && locations[$(this).attr('data-item')].id != '45' && locations[$(this).attr('data-item')].id != '99') emailaddress = '<p class="email">E: <a href="mailto:' + locations[$(this).attr('data-item')].email_on_website + '" target="_blank" title="Neem contact op">' + locations[$(this).attr('data-item')].email_on_website + '</a></p>';
                if (locations[$(this).attr('data-item')].website != null && locations[$(this).attr('data-item')].website != "") website = '<p class="website">W: <a href="http://' + locations[$(this).attr('data-item')].website + '" target="_blank" title="Bezoek de website">' + locations[$(this).attr('data-item')].website + '</a></p>';

                infoBubble = new InfoBubble({
                    map: map,
                    content:
                        '<div class="inner">' +
                        '<h2>' + locations[$(this).attr('data-item')].name + '</h2>' +
                        '<h3><span>Bezoekadres:</span><br>' + locations[$(this).attr('data-item')].address + '</h3>' +
                        '<p>' + locations[$(this).attr('data-item')].zipcode + ' ' + locations[$(this).attr('data-item')].city + '</p>' +
                        website +
                        '</div>',
                    shadowStyle: 0,
                    padding: 0,
                    borderRadius: 0,
                    arrowSize: 10,
                    borderWidth: 0,
                    minWidth: 320,
                    maxWidth: 320,
                    closeSrc: '../img/map/close.png'
                });

                var listener = google.maps.event.addListener(map, "idle", function () {
                    map.setZoom(14);
                });

                infoBubble.open(map, markers[$(this).attr('data-item')]);
            } else {
                window.open(
                    'https://www.google.com/maps/place/' + locations[$(this).attr('data-item')].address.replace(' ', '+') + ',+' + locations[$(this).attr('data-item')].zipcode.replace(' ', '+') + '+' + locations[$(this).attr('data-item')].city + '/',
                    '_blank' // <- This is what makes it open in a new window.
                );
            }

        });

    });

    if (fitBounds) {
        map.fitBounds(bounds);
    }

    var listener = google.maps.event.addListener(map, "idle", function () {
        if (!fitBounds) {
            map.setZoom(7);
        }
        google.maps.event.removeListener(listener);
    });

    var markerCluster = new MarkerClusterer(map, markers, {
        styles: [
            {
                url: '/img/map/cluster_35.svg',
                height: 35,
                width: 35,
                textColor: 'white',
                opt_anchor: [16, 0],
                opt_textColor: '#FFFFFF'
            },
            {
                url: '/img/map/cluster_45.svg',
                height: 45,
                width: 45,
                textColor: 'white',
                opt_anchor: [24, 0],
                opt_textColor: '#FFFFFF'
            },
            {
                url: '/img/map/cluster_55.svg',
                height: 55,
                width: 55,
                textColor: 'white',
                opt_anchor: [32, 0],
                opt_textColor: '#FFFFFF'
            },
            {
                url: 'https://googlemaps.github.io/js-marker-clusterer/images/pin.png',
                height: 48,
                width: 30,
                anchor: [-18, 0],
                textColor: '#ffffff',
                textSize: 10,
                iconAnchor: [15, 48]
            }
        ]
    });
}

function initDienstenSearch() {
    $('#frmDiensten').submit(function (e) {
        e.preventDefault();

        $('#searchaddress').blur();
        $('button').blur();

        $.ajax({
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function () {
                $('#btn-search #loader').fadeIn('fast');
                $('#map-holder .overlay').fadeIn('fast');
                $('#map-holder .overlay .error').fadeOut('fast');
                $('#map-holder .overlay .loading').fadeIn('fast');
            },
            url: '/request/locations',
            data: {
                query: $('#searchaddress').val()
            }
        }).done(function (response) {
            $('#map-holder .overlay #retry-form #search-opnieuw').val($('#searchaddress').val());

            if (JSON.parse(response).error === true) {
                $('#map-holder .overlay .loading').fadeOut('fast');
                $('#map-holder .overlay').fadeIn('fast');
                $('#map-holder .overlay .error').fadeIn('fast');
            }

            initDienstenLijst(response);
            initMaps(response, true);

            setTimeout(function () {
                $('#btn-search #loader').fadeOut('fast');
                $('#map-holder .overlay .loading').fadeOut('fast');

                if (Object.values(JSON.parse(response)).length === 0) {
                    $('#map-holder .overlay').fadeIn('fast');

                    setTimeout(function () {
                        $('#map-holder .overlay .no-results').fadeIn('fast');
                    }, 300);
                } else {
                    $('#map-holder .overlay').fadeOut('fast');
                }
            }, 500);
        });
    });
}

function initDienstenSearchAgain() {
    $('#map-holder .overlay #retry-form #search-opnieuw').val($('#searchaddress').val());

    $('#map-holder .overlay #retry-form').submit(function (e) {
        e.preventDefault();

        $('#map-holder .overlay #retry-form #search-opnieuw').blur();
        $('#map-holder .overlay #retry-form button').blur();

        $.ajax({
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function () {
                $('#btn-search #loader').fadeIn('fast');
                $('#map-holder .overlay').fadeIn('fast');
                $('#map-holder .overlay .error').fadeOut('fast');
                $('#map-holder .overlay .no-results').fadeOut('fast');

                setTimeout(function () {
                    $('#map-holder .overlay .loading').fadeIn('fast');
                }, 300);
            },
            url: '/request/locations',
            data: {
                query: $('#map-holder .overlay #retry-form #search-opnieuw').val(),
                radius: $('#map-holder .overlay #retry-form #radius').val()
            }
        }).done(function (response) {
            $('#searchaddress').val($('#map-holder .overlay #retry-form #search-opnieuw').val());

            if (JSON.parse(response).error === true) {
                $('#map-holder .overlay .loading').fadeOut('fast');
                $('#map-holder .overlay').fadeIn('fast');
                $('#map-holder .overlay .error').fadeIn('fast');
            }

            initDienstenLijst(response);
            initMaps(response, true);

            setTimeout(function () {
                $('#btn-search #loader').fadeOut('fast');
                $('#map-holder .overlay .loading').fadeOut('fast');

                if (Object.values(JSON.parse(response)).length === 0) {
                    $('#map-holder .overlay').fadeIn('fast');

                    setTimeout(function () {
                        $('#map-holder .overlay .no-results').fadeIn('fast');
                    }, 300);
                } else {
                    $('#map-holder .overlay').fadeOut('fast');
                }
            }, 500);
        });
    });
}

function initDienstenSearchError() {
    $('#map-holder .overlay #error-form').submit(function (e) {
        e.preventDefault();

        $('#map-holder .overlay #error-form #search-opnieuw').blur();
        $('#map-holder .overlay #error-form button').blur();

        $.ajax({
            method: 'POST',
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            beforeSend: function () {
                $('#btn-search #loader').fadeIn('fast');
                $('#map-holder .overlay').fadeIn('fast');
                $('#map-holder .overlay .error').fadeOut('fast');
                $('#map-holder .overlay .no-results').fadeOut('fast');

                setTimeout(function () {
                    $('#map-holder .overlay .loading').fadeIn('fast');
                }, 300);
            },
            url: '/request/locations',
            data: {
                query: $('#map-holder .overlay #error-form #search-opnieuw').val(),
                radius: $('#map-holder .overlay #error-form #radius').val()
            }
        }).done(function (response) {
            $('#searchaddress').val($('#map-holder .overlay #error-form #search-opnieuw').val());
            $('#map-holder .overlay #retry-form #search-opnieuw').val($('#map-holder .overlay #error-form #search-opnieuw').val());

            if (JSON.parse(response).error === true) {
                $('#map-holder .overlay .loading').fadeOut('fast');
                $('#map-holder .overlay').fadeIn('fast');

                setTimeout(function () {
                    $('#map-holder .overlay .error').fadeIn('fast');
                }, 300);
            }

            initDienstenLijst(response);
            initMaps(response, true);

            setTimeout(function () {
                $('#btn-search #loader').fadeOut('fast');
                $('#map-holder .overlay .loading').fadeOut('fast');

                if (Object.values(JSON.parse(response)).length === 0) {
                    $('#map-holder .overlay').fadeIn('fast');

                    setTimeout(function () {
                        $('#map-holder .overlay .no-results').fadeIn('fast');
                    }, 300);
                } else {
                    $('#map-holder .overlay').fadeOut('fast');
                }
            }, 500);
        });
    });
}

function slugify(text) {
    return text.toString().toLowerCase()
        .replace(/\s+/g, '-')           // Replace spaces with -
        .replace(/[^\w\-]+/g, '')       // Remove all non-word chars
        .replace(/\-\-+/g, '-')         // Replace multiple - with single -
        .replace(/^-+/, '')             // Trim - from start of text
        .replace(/-+$/, '');            // Trim - from end of text
}